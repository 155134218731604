import { ISignUpSuggestions } from "@faire/web-api/indigofair/data/ISignUpSuggestions";
import { SocialSignOnProvider } from "@faire/web-api/indigofair/data/SocialSignOnProvider";
import { Storage } from "@faire/web/common/Storage";

const SSO_REQUEST_DATA_KEY = "SSO_REQUEST_DATA_KEY";

export type SSORequest = {
  signUpSuggestions?: ISignUpSuggestions;
  id_token?: string;
  provider?: SocialSignOnProvider;
  isActive?: boolean;
};

export const ssoCache = {
  save: saveSSORequest,
  update: updateSSORequest,
  clear: clearSSORequest,
  get: getSSORequest,
};

function updateSSORequest(data: SSORequest) {
  const sessionData = getSSORequest();
  Storage.session.setItem(
    SSO_REQUEST_DATA_KEY,
    JSON.stringify({
      ...sessionData,
      ...data,
    })
  );
}

function saveSSORequest(data: SSORequest) {
  Storage.session.setItem(SSO_REQUEST_DATA_KEY, JSON.stringify(data));
}

function clearSSORequest() {
  Storage.session.removeItem(SSO_REQUEST_DATA_KEY);
}

function getSSORequest(): SSORequest | undefined {
  const data = Storage.session.getItem(SSO_REQUEST_DATA_KEY);
  if (data) {
    try {
      return JSON.parse(data);
    } catch {
      return;
    }
  }
  return;
}
