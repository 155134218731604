import { Core, Decorative } from "@faire/design-tokens";
import { LineItemStyle } from "@faire/web-api/indigofair/data/LineItemStyle";
import { Color } from "@faire/web/slate/Color";
import { getEventTheme } from "@faire/web/slate/Theme/eventsThemeColors";

import { getSaleColorAndAssign } from "@faire/retailer/components/getSaleColorAndAssign";
import { MarketStore } from "@faire/retailer/stores/domain/Events/FaireMarkets/MarketStore";
import { EventsStore } from "@faire/retailer/stores/domain/Events/shared/EventsStore";

import { SERRANO } from "../consts/colors";
import { getSettingAndAssignUpdateSaleColorCopy } from "../settings/getSettingUpdateSaleColorCopy";

export enum LineItemStyleSurface {
  CHECKOUT_ORDER_SUMMARY = "CHECKOUT_ORDER_SUMMARY",
  SINGLE_BRAND_ORDER = "SINGLE_BRAND_ORDER",
  BRAND_CART_SUMMARY = "BRAND_CART_SUMMARY",
  BRAND_CART_HEADER = "BRAND_CART_HEADER",
  INVOICES = "INVOICES",
  HAS_DISCOUNT = "HAS_DISCOUNT",
  BRAND_ORDER_SUMMARY = "BRAND_ORDER_SUMMARY",
  BRAND_LINE_ITEMS_WITH_DROPDOWN = "BRAND_LINE_ITEMS_WITH_DROPDOWN",
  ORDER_SUMMARY_LINE_ITEM = "ORDER_SUMMARY_LINE_ITEM",
  ORDER_SUMMARY = "ORDER_SUMMARY",
  CART_TOTAL_SUMMARY = "CART_TOTAL_SUMMARY",
  BRAND_ORDER_SUMMARY_NAME = "BRAND_ORDER_SUMMARY_NAME",
  EDIT_BRAND_ORDER_SUMMARY = "EDIT_BRAND_ORDER_SUMMARY",
}
enum ClientLineItemStyle {
  MARKET = "MARKET",
  MARKET_NOT_LIVE = "MARKET_NOT_LIVE",
  IS_MARKED_DOWN = "IS_MARKED_DOWN",
  REFUND = "REFUND",
  HAS_DISCOUNT = "HAS_DISCOUNT",
  NO_DISCOUNT = "NO_DISCOUNT",
  INVOICE = "INVOICE",
  IS_DISCOUNT = "IS_DISCOUNT",
  IS_DISCOUNT_AND_BRAND_PROMO = "IS_DISCOUNT_AND_BRAND_PROMO",
}

const getMarketPromoTextColor = () => {
  return (
    getSaleColorAndAssign() ??
    getEventTheme("faireMarket").promoText ??
    getEventTheme("faireMarket").promoTextInverse
  );
};

export type ILineItemStyle =
  | keyof typeof LineItemStyle
  | keyof typeof ClientLineItemStyle
  | undefined;

export const ILineItemStyle = { ...LineItemStyle, ...ClientLineItemStyle };

export const getLineItemColorByStyleAndSurface = (
  style: ILineItemStyle,
  surface: LineItemStyleSurface
): Color | undefined => {
  switch (surface) {
    case LineItemStyleSurface.BRAND_ORDER_SUMMARY_NAME:
      return getLineItemColorByBrandOrderSummaryName(style);
    case LineItemStyleSurface.ORDER_SUMMARY:
      return getLineItemColorByOrderSummary(style);
    case LineItemStyleSurface.CHECKOUT_ORDER_SUMMARY:
      return getCheckoutOrderSummarySurfaceColor();
    case LineItemStyleSurface.SINGLE_BRAND_ORDER:
      return getSingleBrandOrderSurfaceColor(style);
    case LineItemStyleSurface.BRAND_ORDER_SUMMARY:
      return getBrandOrderSummarySurfaceColor(style);
    case LineItemStyleSurface.ORDER_SUMMARY_LINE_ITEM:
      return getOrderSummaryLineItemColor(style);
    case LineItemStyleSurface.CART_TOTAL_SUMMARY:
      return getLineItemColorByCartTotalSummary(style);
    case LineItemStyleSurface.BRAND_CART_SUMMARY:
    case LineItemStyleSurface.INVOICES:
    case LineItemStyleSurface.EDIT_BRAND_ORDER_SUMMARY:
    default:
      return getLineItemColorBase(style);
  }
};

const getBrandOrderSummarySurfaceColor = (style: ILineItemStyle) => {
  switch (style) {
    case ILineItemStyle.FAIRE_PROMOTIONAL_EVENT_PROMO:
      return getMarketPromoTextColor();
    case ILineItemStyle.STRIKETHROUGH:
      return Core.text.disabled;
    case LineItemStyle.BRAND_PROMO:
      return getSaleColorAndAssign();
    case LineItemStyle.FAIRE_PROMO:
    case LineItemStyle.RETAILER_BRAND_LOYALTY_PROMO:
      return getSaleColorAndAssign();
    default:
      return getLineItemColorBase(style);
  }
};

const getCheckoutOrderSummarySurfaceColor = () =>
  MarketStore.get().isLive
    ? getLineItemColorBase(EventsStore.get().eventLineItemStyle)
    : Core.surface.default;

const getOrderSummaryLineItemColor = (style: ILineItemStyle) => {
  switch (style) {
    case ILineItemStyle.FAIRE_PROMOTIONAL_EVENT_PROMO:
      return getMarketPromoTextColor();
    case LineItemStyle.BRAND_PROMO:
      return Decorative.shade.orange;
    default:
      return undefined;
  }
};
const getSingleBrandOrderSurfaceColor = (style: ILineItemStyle) =>
  style === LineItemStyle.BRAND_PROMO ||
  style === LineItemStyle.FAIRE_PROMO ||
  style === LineItemStyle.INSIDER_BENEFIT
    ? getLineItemColorBase(style)
    : undefined;

const getLineItemColorBase = (style: ILineItemStyle): Color => {
  switch (style) {
    case ILineItemStyle.MARKET:
      return getMarketPromoTextColor();
    case ILineItemStyle.INSIDER_BENEFIT:
      return Decorative.shade.teal;

    case ILineItemStyle.MARKET_NOT_LIVE:
    case ILineItemStyle.FREE_DUTIES:
    case ILineItemStyle.FAIRE_PROMO:
    case ILineItemStyle.HAS_DISCOUNT:
    case ILineItemStyle.BRAND_PROMO:
    case ILineItemStyle.R2M_CREDIT:
    case ILineItemStyle.ELEVATE_CREDIT:
    case ILineItemStyle.FAIRE_CREDIT:
      return getSaleColorAndAssign();
    case ILineItemStyle.IS_MARKED_DOWN:
      return Core.surface.subdued;

    case ILineItemStyle.FREE_SHIPPING:
      return getSettingAndAssignUpdateSaleColorCopy()
        ? Core.text.default
        : Decorative.shade.orange;

    case ILineItemStyle.STRIKETHROUGH:
      return Core.surface.disabled; // TODO replace with non-disabled color token

    case ILineItemStyle.REFUND:
      return Decorative.shade.yellow;

    case ILineItemStyle.NO_DISCOUNT:
    default:
      return Core.border.default;
  }
};

const getLineItemColorByOrderSummary = (style: ILineItemStyle): Color => {
  switch (style) {
    case ILineItemStyle.FAIRE_PROMOTIONAL_EVENT_PROMO:
      return getMarketPromoTextColor();
    case ILineItemStyle.BRAND_PROMO:
      return getSaleColorAndAssign();
    case ILineItemStyle.FAIRE_PROMO:
      return getSettingAndAssignUpdateSaleColorCopy()
        ? SERRANO
        : getLineItemColorBase(ILineItemStyle.MARKET_NOT_LIVE);
    case ILineItemStyle.RETAILER_BRAND_LOYALTY_PROMO:
      return getLineItemColorBase(ILineItemStyle.MARKET_NOT_LIVE);
    default:
      return getLineItemColorBase(style);
  }
};

const getLineItemColorByCartTotalSummary = (style: ILineItemStyle): Color => {
  switch (style) {
    case ILineItemStyle.FAIRE_PROMOTIONAL_EVENT_PROMO:
      return getMarketPromoTextColor();
    case LineItemStyle.BRAND_PROMO:
      return getSaleColorAndAssign();
    case LineItemStyle.INSIDER_BENEFIT:
      return Decorative.shade.teal;
    case ILineItemStyle.IS_DISCOUNT:
      return getSaleColorAndAssign();
    case ILineItemStyle.IS_DISCOUNT_AND_BRAND_PROMO:
      return getSaleColorAndAssign();
    default:
      return Core.border.default;
  }
};

const getLineItemColorByBrandOrderSummaryName = (
  style: ILineItemStyle
): Color => {
  switch (style) {
    case ILineItemStyle.STRIKETHROUGH:
      return Core.border.default;
    default:
      return getBrandOrderSummarySurfaceColor(style);
  }
};
