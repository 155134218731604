import { PromotionalEventType } from "@faire/web-api/indigofair/common/PromotionalEventType";
import { IBrandPromoCollection } from "@faire/web-api/indigofair/data/IBrandPromoCollection";
import { ICurrentPromotionalEventResponse } from "@faire/web-api/indigofair/data/ICurrentPromotionalEventResponse";
import { IRetailer } from "@faire/web-api/indigofair/data/IRetailer";
import marketSpecials from "@faire/web-api/routes/markets/event_type/all-specials";
import { clock } from "@faire/web/common/clock";
import { exhaustiveSwitchWithDefault } from "@faire/web/common/exhaustiveSwitchWithDefault";
import { StrictLocalizeFunction } from "@faire/web/common/localization";
import {
  MARKET_FALLBACKS,
  getFaireMarketUtils,
} from "@faire/web/common/promotional-events/getFaireMarketUtils";
import { singletonGetter } from "@faire/web/common/singletons/getter";
import { mockStrictLocalize } from "@faire/web/common/testing/unit/mockStrictLocalize";
import { timestampToMillis } from "@faire/web/common/timestamp";
import { createStoreHook } from "@faire/web/ui/hooks/useStore";
import { utcToZonedTime } from "date-fns-tz";
import differenceInDays from "date-fns/differenceInDays";
import isWithinInterval from "date-fns/isWithinInterval";
import { computed, observable } from "mobx";

import {
  isLoggedInRetailer,
  isLoggedInRetailerOrBrandPreview,
} from "@faire/retailer/lib/isLoggedInRetailer";
import { getMarketEventMatchedDiscountBps } from "@faire/retailer/serialized-data/getMarketEventMatchedDiscountBps";
import { EventsStore } from "@faire/retailer/stores/domain/Events/shared/EventsStore";
import { RetailerStore } from "@faire/retailer/stores/domain/RetailerStore";

/* Various market surfaces are customized for each of these retailer segments.
 * Market components should use these definitions to ensure consistency across all
 * surfaces instead of using RetailerStore or InsiderMembershipInformation directly.
 * These segment definitions are mutually exclusive.
 */
export enum RetailerSegment {
  NOT_LOGGED_IN = "NOT_LOGGED_IN",
  INSIDER_ELIGIBLE = "INSIDER_ELIGIBLE",
  INSIDER_CHURNED = "INSIDER_CHURNED", // Canceled, paused or unpaid
  INSIDER_ENROLLED = "INSIDER_ENROLLED", // Trialing, active
  NOT_INSIDER = "NOT_INSIDER", // Not eligible for Insider
}

/**
 * @deprecated prefer to use `useMarketStore` or `withStores` instead
 */
export class MarketStore {
  static get = singletonGetter(MarketStore);

  @observable
  private strictLocalize: StrictLocalizeFunction | undefined;

  setStrictLocalize = (strictLocalize: StrictLocalizeFunction) => {
    this.strictLocalize = strictLocalize;
  };

  @computed
  private get strictLocalizeFunction(): StrictLocalizeFunction {
    return this.strictLocalize ?? mockStrictLocalize;
  }

  @computed
  get currentEventType(): PromotionalEventType {
    return (
      (this.latestMarketEvent?.event?.event_type as PromotionalEventType) ??
      MARKET_FALLBACKS(this.strictLocalizeFunction).currentEventType
    );
  }

  @computed
  get marketName(): string {
    return (
      this.latestMarketEvent?.event?.event_name ??
      MARKET_FALLBACKS(this.strictLocalizeFunction).name
    );
  }

  @computed
  private get latestMarketEvent():
    | ICurrentPromotionalEventResponse
    | undefined {
    const maybeEvent = EventsStore.get().nextOrActive;
    if (
      maybeEvent?.event?.event_group ===
      ICurrentPromotionalEventResponse.IPromotionalEvent.PromotionalEventGroup
        .MARKET
    ) {
      return maybeEvent;
    }

    return undefined;
  }

  @computed
  private get marketYear(): string | undefined {
    return this.latestMarketEvent?.start_at
      ? new Date(this.latestMarketEvent.start_at).getFullYear().toString()
      : undefined;
  }

  @computed
  get latestMarketEarlyAccessRangeDate(): string {
    const { earlyAccessRangeDate } = getFaireMarketUtils(
      this.marketYear,
      this.strictLocalizeFunction
    );
    return earlyAccessRangeDate(
      this.strictLocalizeFunction,
      this.latestMarketEvent
    );
  }

  @computed
  get latestMarketEarlyAccessRangeDateWithYear(): string {
    const { earlyAccessRangeDateWithYear } = getFaireMarketUtils(
      this.marketYear,
      this.strictLocalizeFunction
    );
    return earlyAccessRangeDateWithYear(
      this.strictLocalizeFunction,
      this.latestMarketEvent
    );
  }

  @computed
  get latestMarketMainEventRangeDate(): string {
    const { mainEventRangeDate } = getFaireMarketUtils(
      this.marketYear,
      this.strictLocalizeFunction
    );
    return mainEventRangeDate(
      this.strictLocalizeFunction,
      this.latestMarketEvent
    );
  }

  @computed
  get retailerSegment(): RetailerSegment {
    if (!isLoggedInRetailerOrBrandPreview()) {
      return RetailerSegment.NOT_LOGGED_IN;
    }
    // The following segments are defined here:
    // https://www.figma.com/file/Bw5FNGTFa0kAK4OSSGILWk/FWM-2022-Experience?node-id=3571%3A17720
    if (
      RetailerStore.get().insiderMembershipState ===
      IRetailer.InsiderMembershipState.ELIGIBLE
    ) {
      return RetailerSegment.INSIDER_ELIGIBLE;
    }
    switch (RetailerStore.get().insiderMembershipState) {
      case IRetailer.InsiderMembershipState.CHURNED:
      case IRetailer.InsiderMembershipState.PAUSED:
      case IRetailer.InsiderMembershipState.UNPAID:
        return RetailerSegment.INSIDER_CHURNED;
      case IRetailer.InsiderMembershipState.ACTIVE:
        return RetailerSegment.INSIDER_ENROLLED;
      default:
        return RetailerSegment.NOT_INSIDER;
    }
  }

  @computed
  get showFaireDirectBanner(): boolean {
    return this.showMarketLandingPage;
  }

  @computed
  get showAllSpecials(): boolean {
    return (
      !this.hasEnded &&
      this.retailerSegment !== RetailerSegment.NOT_LOGGED_IN &&
      this.isMarketListLaunched
    );
  }

  @computed
  get showMarketList(): boolean {
    return this.showAllSpecials;
  }

  @computed
  private get isAcquisitionSurfaceEnabled(): boolean {
    /**
     * TODO(FD-146033): switch to using isAcquisitionSurfaceEnabled once we start using FeaturedPromotionalEvent
     * Brand portal and acquisition surfaces are launching at the same time for FSM23, so using the same field for now.
     */
    const brandPortalLaunchTime = timestampToMillis(
      this.eventData.brand_portal_launch_time
    );
    if (brandPortalLaunchTime === undefined) {
      return false;
    }
    return clock.now() >= brandPortalLaunchTime;
  }

  @computed
  get showMarketLandingPage(): boolean {
    return !this.hasEnded && this.isAcquisitionSurfaceEnabled;
  }

  @computed
  get showBrandLandingPage(): boolean {
    return !this.hasEarlyAccesStarted && this.isAcquisitionSurfaceEnabled;
  }

  @computed
  get showPreshowTreatment(): boolean {
    const endTime = this.eventData.end_at;
    if (this.preShowLaunchAt === -1 || endTime === undefined) {
      return false;
    }
    return clock.now() >= this.preShowLaunchAt && clock.now() < endTime;
  }

  @computed
  get isPreshowOrLive(): boolean {
    return this.showPreshowTreatment || this.isLive;
  }

  @computed
  get showNavHeader(): boolean {
    return this.showMarketList;
  }

  @computed
  get isInsiderDay(): boolean {
    return (
      this.retailerSegment === RetailerSegment.INSIDER_ENROLLED &&
      MarketStore.get().isEarlyAccessDay
    );
  }

  // Current market state excluding logged out retailers
  get currentState(): MarketState {
    if (this.retailerSegment === RetailerSegment.NOT_LOGGED_IN) {
      return MarketState.DISABLED;
    }

    return this._currentState;
  }

  @computed
  get currentStateIncludingLoggedOut(): MarketState {
    return this._currentState;
  }

  private get _currentState(): MarketState {
    if (
      !this.mainEventStartAt ||
      !this.mainEventEndAt ||
      !this.earlyAccessStartAt
    ) {
      return MarketState.DISABLED;
    }

    const now = clock.now();

    if (
      isWithinInterval(now, {
        start: this.earlyAccessStartAt,
        end: this.mainEventStartAt,
      })
    ) {
      return MarketState.EARLY_ACCESS;
    }

    if (
      isWithinInterval(now, {
        start: this.mainEventStartAt,
        end: this.mainEventEndAt,
      })
    ) {
      return MarketState.MAIN_EVENT;
    }

    return MarketState.DISABLED;
  }

  // Check if is Insider early access day regardless of the retailer state
  get isEarlyAccessDay(): boolean {
    return this._currentState === MarketState.EARLY_ACCESS;
  }

  get nextStateStartAt(): number {
    switch (this.currentState) {
      case MarketState.DISABLED:
        if (clock.now() < this.earlyAccessStartAt) {
          return this.earlyAccessStartAt;
        }
        return 0;
      case MarketState.EARLY_ACCESS:
        return this.mainEventStartAt;
      case MarketState.MAIN_EVENT:
        return this.mainEventEndAt;
      default:
        return exhaustiveSwitchWithDefault(0)(this.currentState);
    }
  }

  private get nextStateStartAtBasedInsiderEnrollment(): number {
    if (
      this.retailerSegment !== RetailerSegment.INSIDER_ENROLLED &&
      this.retailerSegment !== RetailerSegment.INSIDER_ELIGIBLE &&
      this.currentState === MarketState.DISABLED &&
      clock.now() < this.earlyAccessStartAt
    ) {
      return this.mainEventStartAt;
    }
    return this.nextStateStartAt;
  }

  @computed
  get preShowLaunchAt(): number {
    const preShowLaunchTime = timestampToMillis(
      this.eventData.retailer_pre_show_launch_time
    );
    return preShowLaunchTime ?? -1;
  }

  @computed
  private get marketListLaunchAt(): number {
    const marketListLaunchTime = timestampToMillis(
      this.eventData.market_list_launch_time
    );
    return marketListLaunchTime ?? -1;
  }

  @computed
  get isMarketListLaunched(): boolean {
    return (
      this.marketListLaunchAt > -1 &&
      clock.now() >= this.marketListLaunchAt &&
      !this.hasEnded
    );
  }

  @computed
  get earlyAccessStartAt(): number {
    return this.eventData.insider_early_access_start_at
      ? this.eventData.insider_early_access_start_at
      : MARKET_FALLBACKS(this.strictLocalize ?? mockStrictLocalize)
          .earlyAccessStartAtTimestamp;
  }

  @computed
  get formattedEarlyAccessStartDay(): string {
    const marketUtils = getFaireMarketUtils(
      undefined,
      this.strictLocalize ?? mockStrictLocalize
    );
    return marketUtils.formatDateInMarketTimezone(this.earlyAccessStartAt);
  }

  @computed
  get formattedLastDay(): string {
    const marketUtils = getFaireMarketUtils(
      undefined,
      this.strictLocalize ?? mockStrictLocalize
    );
    return marketUtils.formatDateInMarketTimezone(this.mainEventEndAt);
  }

  @computed
  private get mainEventStartAt(): number {
    return this.eventData.start_at
      ? this.eventData.start_at
      : MARKET_FALLBACKS(this.strictLocalize ?? mockStrictLocalize)
          .mainEventStartAtTimestamp;
  }

  @computed
  private get actualStartAt(): number {
    return this.eventData.start_at
      ? this.eventData.start_at
      : MARKET_FALLBACKS(this.strictLocalize ?? mockStrictLocalize)
          .mainEventStartAtTimestamp;
  }

  @computed
  get mainEventEndAt(): number {
    return this.eventData.end_at
      ? this.eventData.end_at
      : MARKET_FALLBACKS(this.strictLocalize ?? mockStrictLocalize)
          .endAtTimestamp;
  }

  // Event start time for the current retailer, taking into consideration of the retailer
  // segment (i.e. logged out vs. Insider vs. not Insider).
  @computed
  get startAt(): number {
    switch (this.retailerSegment) {
      case RetailerSegment.INSIDER_ENROLLED:
        return this.earlyAccessStartAt;
      default:
        return this.mainEventStartAt;
    }
  }

  get isLive(): boolean {
    switch (this.currentState) {
      case MarketState.EARLY_ACCESS:
        return this.retailerSegment === RetailerSegment.INSIDER_ENROLLED;
      case MarketState.MAIN_EVENT:
        return true;
      case MarketState.DISABLED:
        return false;
      default:
        return exhaustiveSwitchWithDefault(false)(this.currentState);
    }
  }

  get hasEnded(): boolean {
    return clock.now() > this.mainEventEndAt;
  }

  private get hasEarlyAccesStarted(): boolean {
    return clock.now() > this.earlyAccessStartAt;
  }

  get hasCountdownEnded(): boolean {
    return this.hasEnded;
  }

  @computed
  get eventDaysPassed(): number {
    return Math.max(
      0,
      differenceInDays(clock.now(), this.actualStartAt.valueOf())
    );
  }

  @computed
  get eventDaysLeft(): number {
    return Math.max(
      0,
      differenceInDays(this.mainEventEndAt.valueOf(), clock.now())
    );
  }

  @computed
  private get eventData(): ICurrentPromotionalEventResponse {
    return (
      EventsStore.get().eventData(MarketStore.get().currentEventType) ??
      ICurrentPromotionalEventResponse.build({
        start_at: 0,
        end_at: 0,
        insider_early_access_start_at: 0,
      })
    );
  }

  @computed
  get matchedDiscountCapBps(): number {
    const marketEventMatchedDiscountBps = getMarketEventMatchedDiscountBps();
    return marketEventMatchedDiscountBps === 0
      ? 500
      : marketEventMatchedDiscountBps;
  }

  @computed
  get marketSpecialsRoute(): string {
    return marketSpecials(this.currentEventType);
  }

  get isLiveForCountdownBanner(): boolean {
    return this.isLive;
  }

  get deadlineForCountdownBanner(): number {
    return this.nextStateStartAtBasedInsiderEnrollment;
  }

  @computed
  get showCountdownBanner(): boolean {
    return this.isPreshowOrLive;
  }

  @observable
  private hasMarketCountdownBanner = false;

  setHasMarketCountdownBanner = (hasMarketCountdownBanner: boolean) => {
    this.hasMarketCountdownBanner = hasMarketCountdownBanner;
  };

  @computed
  get isShowingCountdownBanner(): boolean {
    return (
      isLoggedInRetailer() &&
      this.hasMarketCountdownBanner &&
      this.showCountdownBanner
    );
  }

  isPreOrderExcludedForBrandPromo = (
    eventKey: string | undefined,
    shipAvailability:
      | keyof typeof IBrandPromoCollection.ProductShipAvailability
      | undefined
  ): boolean => {
    return (
      eventKey !== undefined &&
      shipAvailability ===
        IBrandPromoCollection.ProductShipAvailability
          .ONLY_IMMEDIATE_PRODUCTS_DISCOUNTED
    );
  };

  /**
   * This method takes a snapped timestamp (e.g. 9am PT) and converts it to a timestamp
   * that corresponds to the same time in the local timezone (9am ET).
   */
  unsnapFromPT = (timeUtc: number): number => {
    return utcToZonedTime(timeUtc, PT_TIMEZONE).valueOf();
  };
}

export const useMarketStore = createStoreHook(MarketStore);

export enum MarketState {
  EARLY_ACCESS = "EARLY_ACCESS",
  MAIN_EVENT = "MAIN_EVENT",
  DISABLED = "DISABLED",
}

const PT_TIMEZONE = "America/Los_Angeles";
