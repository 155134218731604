import { Decorative } from "@faire/design-tokens";

import { SERRANO } from "@faire/retailer/consts/colors";
import { getSettingAndAssignUpdateSaleColorCopy } from "@faire/retailer/settings/getSettingUpdateSaleColorCopy";

export const getSaleColorAndAssign = () => {
  return getSettingAndAssignUpdateSaleColorCopy()
    ? SERRANO
    : Decorative.shade.orange;
};
