"use client";

import { localeStringToLocaleKey } from "@faire/web/common/consts/TWO_TO_THREE_LETTER_LANGUAGE_CODE";
import { IntlProvider } from "@faire/web/common/localization/IntlProvider";
import { TRANSLATION_LOCALES } from "@faire/web/common/rsc/localization/TRANSLATION_LOCALES";
import { getTranslationLocale } from "@faire/web/common/rsc/localization/utils";
import { isWindowUndefined } from "@faire/web/common/server-side-rendering/isWindowUndefined";
import fs from "fs";
import React, { ReactNode } from "react";

export const IntlProviderForSSR_Base = ({
  children,
  locale,
}: {
  children: ReactNode;
  locale: string;
}) => {
  if (!isWindowUndefined()) {
    return children;
  }

  const localizationLocale = getTranslationLocale(locale);
  const localeKey = localeStringToLocaleKey(localizationLocale);

  return (
    <IntlProvider
      localeKey={localeKey}
      messages={getDictionary(localizationLocale)}
    >
      {children}
    </IntlProvider>
  );
};

const getDictionary = (
  translationLocale: (typeof TRANSLATION_LOCALES)[number]
) => {
  if (dictionaryCache.has(translationLocale)) {
    return dictionaryCache.get(translationLocale);
  }
  const filename = `./node_modules/@faire/web-translations/${translationLocale}.json`;

  try {
    const dictionary = JSON.parse(fs.readFileSync(filename, "utf8"));
    dictionaryCache.set(translationLocale, dictionary);
    return dictionary;
  } catch (e) {
    throw new Error(
      `Failed to read translation file for ${translationLocale}. File pathname=${filename}. Error=${e}`
    );
  }
};

const dictionaryCache = new Map();
